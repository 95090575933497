import React, { useState, useRef, useCallback, useEffect } from "react";
import CForm from "./components/form";
import Card from "./components/card";

const initialState = {
  cardNumber: "#### #### #### ####",
  cardHolder: "FULL NAME",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  isCardFlipped: false,
};

const MainScreen = (props) => {
  const [state, setState] = useState(initialState);
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const updateStateValues = useCallback(
    (keyName, value) => {
      setState({
        ...state,
        [keyName]: value || initialState[keyName],
      });
    },
    [state]
  );
  const validateCardNumber = (cardNumber) => {
    // Rimuove gli spazi dalla stringa
    const cleanedCardNumber = cardNumber.replace(/\s+/g, "");

    // Regex per le varie tipologie di carte di credito
    const cardRegexes = {
      visa: /^4\d{12}(\d{3})?$/,
      mastercard: /^(5[1-5]\d{4}|2[2-7]\d{4})\d{10}$/,
      amex: /^3[47]\d{13}$/,
      discover: /^6(011|5\d{2}|22(1[26-9]|[2-8]\d|9[0-25]))\d{12}$/,
      diners: /^3(0[0-5]|[68]\d)\d{11}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
      maestro: /^(5018|5020|5038|6304|6759|6761|6763)\d{8,15}$/,
      unionplay: /^(62|81)\d{14,17}$/,
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d{10}$/,
    };

    // Controlla se il numero di carta di credito corrisponde a uno dei regex
    for (let key in cardRegexes) {
      if (cardRegexes[key].test(cleanedCardNumber)) {
        return true;
      }
    }

    // Se nessuno dei regex corrisponde, restituisce false
    return false;
  };

  const validateCardHolder = (name) => {
    if (name === "FULL NAME") {
      return false;
    }
    //const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-öø-ÿ]+)+$/;
    const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿ.-]+( [a-zA-ZÀ-ÖØ-öø-ÿ.-]+)+$/;
    return regex.test(name.trim().replace(/\s+/g, " "));
  };

  const validateCardCvv = (cvv) => {
    const regex = /^\d{3,4}$/;
    return regex.test(cvv);
  };
  useEffect(() => {
    var new_disabled = false;

    if (state.cardNumber === "" || !validateCardNumber(state.cardNumber)) {
      new_disabled = true;
    }
    if (state.cardHolder === "" || !validateCardHolder(state.cardHolder)) {
      new_disabled = true;
    }
    if (state.cardMonth === "") {
      new_disabled = true;
    }
    if (state.cardYear === "") {
      new_disabled = true;
    }
    if (state.cardCvv === "" || !validateCardCvv(state.cardCvv)) {
      new_disabled = true;
    }

    setIsDisabled(new_disabled);
  }, [state]);

  // References for the Form Inputs used to focus corresponding inputs.
  let formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  let focusFormFieldByKey = useCallback((key) => {
    formFieldsRefObj[key].current.focus();
  });

  // This are the references for the Card DIV elements.
  let cardElementsRef = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
  };

  let onCardFormInputFocus = (_event, inputName) => {
    const refByName = cardElementsRef[inputName];
    setCurrentFocusedElm(refByName);
  };

  let onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  const submit = () => {
    props.submit(state);
  };

  return (
    <div className="wrapper">
      <CForm
        cardMonth={state.cardMonth}
        cardYear={state.cardYear}
        onUpdateState={updateStateValues}
        cardNumberRef={formFieldsRefObj.cardNumber}
        cardHolderRef={formFieldsRefObj.cardHolder}
        cardDateRef={formFieldsRefObj.cardDate}
        onCardInputFocus={onCardFormInputFocus}
        onCardInputBlur={onCardInputBlur}
        submit={submit}
        isDisabled={isDisabled}
        language={props.language}
      >
        <Card
          cardNumber={state.cardNumber}
          cardHolder={state.cardHolder}
          cardMonth={state.cardMonth}
          cardYear={state.cardYear}
          cardCvv={state.cardCvv}
          isCardFlipped={state.isCardFlipped}
          currentFocusedElm={currentFocusedElm}
          onCardElementClick={focusFormFieldByKey}
          cardNumberRef={cardElementsRef.cardNumber}
          cardHolderRef={cardElementsRef.cardHolder}
          cardDateRef={cardElementsRef.cardDate}
          country={props.country}
          language={props.language}
        ></Card>
      </CForm>
    </div>
  );
};

export default MainScreen;
